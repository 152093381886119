import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { Paragraph } from "grommet"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { Layout } from "@bw/layouts"
import { UserConnect } from "@bw/partials"
import { Section, Button } from "@bw/bits"
import { FormField, Input } from "@bw/forms"
import { getAccessToken } from "@actions/user"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import { useUser } from "@contexts/user"
import { Formik, Form, Field } from "formik"
import styled from "styled-components"
import * as yup from "yup"

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
`

function Login({ pageContext }) {
  const { localizedPath, locale, defaultLang } = useLocalization()
  const { originalPath } = pageContext
  const { t } = useTranslation()
  const [user, dispatch] = useUser()

  React.useEffect(() => {
    if (user.loggedIn) {
      navigate(
        localizedPath({
          defaultLang,
          locale,
          path: `/user/${user.publicKey}/`,
        })
      )
    }
  }, [user, defaultLang, locale, localizedPath])

  return (
    <Layout {...{ pageContext }}>
      <Section
        title={t("user.form.login.title")}
        subtitle={t("user.form.login.subtitle")}
      />
      <Section>
        {!user.publicKey ? (
          <UserConnect />
        ) : (
          <Formik
            validationSchema={yup.object().shape({
              email: yup.string().required().email(),
              password: yup.string().required(),
            })}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              getAccessToken(values.email, values.password, dispatch)
                .then(response => {
                  if (response.data.hasOwnProperty("error")) {
                    toast.error(t("user.toastLoginFailed"))
                  }
                  localizedPath({
                    defaultLang,
                    locale,
                    path: `/user/${user.publicKey}/`,
                  })
                })
                .catch(err => {
                  toast.error(t("user.toastLoginFailed"))

                  // these are server validation errors
                  err?.response?.data?.errors &&
                    Object.values(err.response.data.errors).forEach(error => {
                      toast.error(error.join("\n"))
                    })
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ width: "316px" }} data-cy="login-form">
                <FormField name="email" title={t("user.form.email")}>
                  <Field name="email" type="email" component={Input} />
                </FormField>
                <FormField name="password" title={t("user.form.password")}>
                  <Field name="password" type="password" component={Input} />
                </FormField>
                <div>
                  <p>
                    <Button
                      submit
                      data-cy="login-submit"
                      label={t("user.form.buttonLogin")}
                      disabled={isSubmitting}
                    />
                  </p>
                  <Paragraph size="small">
                    {t("user.form.login.passwordForgottenSentence")} &nbsp;
                    <StyledLink to="/user/forgot-password/">
                      {t("user.form.login.passwordForgotten")}
                    </StyledLink>
                  </Paragraph>
                  <Paragraph size="small">
                    {t("user.form.login.registerSentence")} &nbsp;
                    <StyledLink to={t("links:register.path")}>
                      {t("user.form.login.register")}
                    </StyledLink>
                  </Paragraph>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Section>
    </Layout>
  )
}

export default Login
